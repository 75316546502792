import heartbeatManager from '@/offline/managers/HeartbeatManager'

import { BackgroundSyncManagerInterface } from '../interfaces/BackgroundSyncManagerInterface'
import { LocalDataManagerInterface } from '../interfaces/LocalDataManagerInterface'
import { SymmetricKeyManagerInterface } from '../interfaces/SymmetricKeyManagerInterface'
import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'
import { iseExistsInCache } from '../helpers'
import { encryptSymmetrically } from '../services/DefaultCryptographyService'

import SyncEventBusManager from './SyncEventBusManager'

const ONE_HOUR_IN_MILLIS = 60 * 60 * 1000
const RETRYABLE_STATUS_CODES = new Set([408, 425, 429, 500, 502, 503, 504])
const fetchAfterTimeout = (
  url: string,
  timeout: number,
  fetchOptions: RequestInit = { cache: 'reload' },
): Response | PromiseLike<Response> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fetch(url, fetchOptions).then(resolve).catch(reject)
    }, timeout)
  })
}
const fetchWithRetry = async (url: string, retryAttempts: number) => {
  let count = 0
  let timeout = 1000
  let resp

  try {
    resp = await fetch(url)
  } catch {
    timeout *= 2
    count++
  }

  // retry if fetch throws
  while (!resp && count < retryAttempts) {
    try {
      resp = await fetchAfterTimeout(url, timeout)
    } catch {
      timeout *= 2
      count++
    }
  }

  if (!resp) {
    throw new Error(`Failed to fetch after ${retryAttempts} attempts`)
  }

  // retry if the request failed due to a retryable status code
  while (RETRYABLE_STATUS_CODES.has(resp.status) && count < retryAttempts) {
    resp = await fetchAfterTimeout(url, timeout)

    timeout *= 2
    count++
  }

  return resp
}

export default class BackgroundSyncManager
  implements BackgroundSyncManagerInterface
{
  private interval: number
  private intervalId: number | null
  private syncEventBusManager: SyncEventBusManagerInterface
  private retryAttempts: number
  private symmetricKeyManager: SymmetricKeyManagerInterface
  private localDataManager: LocalDataManagerInterface
  private bundleHash: string

  /**
   * Instantiates a new BackgroundSyncManager instance
   * @param symmetricKeyManager The manager for retrieving and storing a symmetric key
   * @param interval The interval at which to sync with the backend
   * @param retryAttempts The number of times to retry requests to the backend when attempting to cache a response
   */
  constructor(
    localDataManager: LocalDataManagerInterface,
    symmetricKeyManager: SymmetricKeyManagerInterface,
    interval = 1000 * 60 * 60,
    retryAttempts = 3,
  ) {
    this.interval = interval
    this.intervalId = null
    this.syncEventBusManager = new SyncEventBusManager()
    this.retryAttempts = retryAttempts
    this.symmetricKeyManager = symmetricKeyManager
    this.localDataManager = localDataManager
    this.bundleHash = process.env.BUNDLE_HASH || 'bundle' // 'bundle' || 'v4uuid'

    this.syncEventBusManager.addEventListener('RETRY', async () => {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.START, {
          detail: { message: 'starting background sync' },
        }),
      )

      try {
        await this.syncNewSession()
        await this.syncSharedAssets()
        await this.syncSessions()
        await this.syncClientsPage()
        await this.syncLogout()
        await this.syncTreatmentPlan()
      } catch (e) {
        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
            detail: { error: e },
          }),
        )
      }

      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
          detail: { message: 'background sync completed successfully' },
        }),
      )
    })
  }

  /**
   * Start the periodic sync of data with the backend. Called as part of the
   * offline app initialization process on every page load.
   */
  async startBackgroundSync() {
    if (!this.isUserLoggedIn()) {
      return
    }

    this.syncEventBusManager.dispatch(
      new CustomEvent(SYNC_EVENT_TYPES.START, {
        detail: { message: 'starting background sync' },
      }),
    )

    const oneHourAgo = Date.now() - ONE_HOUR_IN_MILLIS
    let lastUpdatedAt = await this.localDataManager.getLastUpdatedAt()
    const urlExistsInCache = await iseExistsInCache()

    if (!lastUpdatedAt || lastUpdatedAt < oneHourAgo || !urlExistsInCache) {
      try {
        await this.syncSharedAssets()
        await this.syncSessions()
        await this.syncClientsPage()
        await this.syncWaitingPage()
        await this.syncSymmetricKey()
        await this.syncLogout()
        await this.syncNewSession()
        await this.syncOfflineSessionPage()
        await this.syncTreatmentPlan()
      } catch (e) {
        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
            detail: { error: e },
          }),
        )
      }
      const now = Date.now()
      await this.localDataManager.setLastUpdatedAt(now)
      lastUpdatedAt = now
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
          detail: { message: 'background sync completed successfully' },
        }),
      )
    }

    setTimeout(
      () => {
        // start background sync
        this.intervalId = window.setInterval(() => {
          this.syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.START, {
              detail: { message: 'starting background sync' },
            }),
          )

          this.syncSessions()

          this.syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
              detail: { message: 'background sync completed successfully' },
            }),
          )
        }, this.interval)
      },
      Date.now() - (lastUpdatedAt + ONE_HOUR_IN_MILLIS),
    )
  }

  /**
   * Stop the periodic background sync of data with the backend
   */
  stopBackgroundSync() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    } else {
      console.warn(
        'attempted to stop background sync when background sync was not running',
      )
    }
  }

  /**
   * Sync the sessions page and all ISEs it links to.
   * @returns Promise<void>
   */
  private async syncSessions() {
    await this.fetchAndCacheStatics('Sessions page', [
      `/static/assets/js/ise.${this.bundleHash}.js`,
      '/static/js/sessionGeolocation.js',
      '/static/audio/chime.wav',
    ])

    // DBW 8/6/24: We don't use the fetchAndCache methods because we do post-processing
    // of the sessions page after we put it in the cache, and fetchAndCache doesn't \
    // return the responses.
    const resp = await fetchWithRetry('/sessions/', this.retryAttempts)
    if (resp.status >= 400) {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { message: 'Failed to sync sessions page' },
        }),
      )
      return
    }
    const cacheTwyllOffline = await caches.open('twyll-offline')
    cacheTwyllOffline.put('/sessions/', resp.clone())

    const body = await resp.text()
    const parser = new DOMParser()
    const html = parser.parseFromString(body, 'text/html')

    const links = Array.from(
      html.querySelectorAll('a[data-testid="sessionIdentityButton"]'),
    )
      .map((link) => link.getAttribute('href'))
      .filter((link) => link !== null)

    this.fetchAndCacheDynamics('ISE', links)
  }

  /**
   * Sync the logout page and its statics.
   * @returns Promise<void>
   */
  private async syncLogout() {
    const htmlResp = await fetchWithRetry(
      '/accounts/logout/',
      this.retryAttempts,
    )
    const cache = await caches.open('twyll-offline')
    cache.put('/accounts/logout/', htmlResp.clone())

    return this.fetchAndCacheStatics('logout page', ['/static/js/logout.js'])
  }

  /**
   * Sync the waiting page and its bundle
   */
  private async syncWaitingPage() {
    const routeWaitingJsBundle = `/static/assets/js/waiting.${this.bundleHash}.js`

    const html = await fetchWithRetry('/sessions/waiting', this.retryAttempts)
    const reactApp = await fetchWithRetry(
      routeWaitingJsBundle,
      this.retryAttempts,
    )
    const translations = await fetchWithRetry(
      '/static/locales/en-us/offline.json',
      this.retryAttempts,
    )

    const [cacheTwyllOffline, cacheStaticResources] = await Promise.all([
      caches.open('twyll-offline'),
      caches.open('static-resources'),
    ])

    cacheTwyllOffline.put('/session/waiting', html.clone())
    cacheStaticResources.put(routeWaitingJsBundle, reactApp.clone())
    cacheStaticResources.put(
      '/static/locales/en-us/offline.json',
      translations.clone(),
    )
  }

  /**
   * Send a POST request to create a new session record in db which was created in offline mode
   */
  private async syncNewSession() {
    const newSessionData = localStorage.getItem('new-session')
    if (newSessionData && heartbeatManager.isOnline) {
      try {
        const { clientId, formattedData: payload } = JSON.parse(newSessionData)
        const response = await fetch(
          new URL(
            `${window.location.origin}/api/offline/client/${clientId}/new_session/`,
          ),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': window.CSRFTOKEN,
              Accept: 'application/json',
            },
            body: JSON.stringify(payload),
          },
        )

        if (response.status === 201) {
          localStorage.removeItem('new-session')
        } else {
          console.error(`Failed to sync session: ${response.statusText}`)
        }
      } catch (e) {
        if (e instanceof Error) {
          console.error(`Error during session sync: ${e.message}`)
        }
      }
    }
  }

  /**
   * Caching page to render ISE for offline created session
   */
  private async syncOfflineSessionPage() {
    const bundleHash = process.env.BUNDLE_HASH // 'bundle' || 'v4uuid'
    const routeOfflineSessionJsBundle = `/static/assets/js/offline_session.${bundleHash}.js`

    const html = await fetchWithRetry(
      '/sessions/offline-session',
      this.retryAttempts,
    )
    const reactApp = await fetchWithRetry(
      routeOfflineSessionJsBundle,
      this.retryAttempts,
    )

    const [cacheTwyllOffline, cacheStaticResources] = await Promise.all([
      caches.open('twyll-offline'),
      caches.open('static-resources'),
    ])

    cacheTwyllOffline.put('/session/offline-session', html.clone())
    cacheStaticResources.put(routeOfflineSessionJsBundle, reactApp.clone())
  }

  /**
   * Sync the encrypted treatment plan
   */
  private async syncTreatmentPlan() {
    try {
      const response = await fetch(
        new URL(`${window.location.origin}/api/offline/client/`),
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': window.CSRFTOKEN,
            Accept: 'application/json',
          },
        },
      )
      if (response.status === 200) {
        const treatmentPlan = await response.json()
        const treatmentPlanJSON = JSON.stringify(treatmentPlan)
        const cryptoKey = await this.symmetricKeyManager.get()
        const encryptedTreatmentPlan = await encryptSymmetrically(
          cryptoKey,
          treatmentPlanJSON,
        )
        const treatmentPlanResponse = new Response(encryptedTreatmentPlan)
        const cacheTwyllOffline = await caches.open('twyll-offline')

        cacheTwyllOffline.put('/api/offline/client/', treatmentPlanResponse)
      } else {
        console.error(`Failed to sync treatment plan: ${response.statusText}`)
      }
    } catch (e) {
      if (e instanceof Error) {
        console.error(`Error during treatment plan sync: ${e.message}`)
      }
    }
  }

  /**
   * Sync the symmetric key used for encrypting offline events
   */
  private async syncSymmetricKey() {
    // DBW 6/21/24 -- this returns the key, but we only need to refresh the cache,
    // so we drop the return value on the floor
    try {
      await this.symmetricKeyManager.fetchAndCache()
    } catch (e) {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { error: e },
        }),
      )
    }
  }

  /**
   * Sync the clients page and its API calls
   * @returns Promise<void>
   */
  private async syncClientsPage() {
    await this.fetchAndCacheDynamics('Clients page', [
      '/clients/',
      '/api/clients-api/v1/clients/filters/',
      '/api/clients-api/v1/clients/?search=',
    ])

    return this.fetchAndCacheStatics('clients page', [
      '/static/locales/en-us/clients.json',
      `/static/assets/js/clients.${this.bundleHash}.js`,
    ])
  }

  /**
   * Sync shared assets like CSS and JS that are used by many pages
   * @returns Promise<void>
   */
  private async syncSharedAssets() {
    return this.fetchAndCacheStatics('shared assets', [
      '/static/assets/css/app.css',
      `/static/assets/js/offline.${this.bundleHash}.js`,
      '/static/js/analytics.js',
      '/static/js/copy-paste.js',
      '/static/assets/images/[chunkhash].gif',
    ])
  }

  /**
   * Fetch and cache a list of static resources
   * @param pageName The name of the page we're fetching and caching for
   * @param resources The list of static resource urls to fetch
   * @returns Promise<void>
   */
  private async fetchAndCacheStatics(pageName: string, resources: string[]) {
    return this.fetchAndCache(pageName, resources, 'static-resources')
  }

  /**
   * Fetch and cache a list of dynamic resources
   * @param pageName The name of the page we're fetching and caching for
   * @param resources The list of dynamic resource urls to fetch
   * @returns
   */
  private async fetchAndCacheDynamics(pageName: string, resources: string[]) {
    return this.fetchAndCache(pageName, resources, 'twyll-offline')
  }

  /**
   * Fetch and cache a list of urls
   * @param pageName The name of the page we're fetching and caching for
   * @param resources The list of urls to fetch
   * @param cacheName The cache to cache the responses in
   * @returns Promise<void>
   */
  private async fetchAndCache(
    pageName: string,
    resources: string[],
    cacheName: string,
  ) {
    const responses: Response[] = []

    // for of - for making all requests sequentially
    // to avoid overloading server
    for (const resource of resources) {
      responses.push(await fetchWithRetry(resource, this.retryAttempts))
    }

    if (responses.some((response) => response.status >= 400)) {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { message: `Failed to sync ${pageName}` },
        }),
      )
      return
    }

    const cache = await caches.open(cacheName)
    responses.forEach((resp) => {
      cache.put(resp.url, resp.clone())
    })
  }

  private isUserLoggedIn() {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('verification_token='))
      ?.split('=')[1]
    return !!cookieValue
  }
}
